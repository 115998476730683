import { getCookie, deleteCookie } from '../utils/cookies'
import gsap from 'gsap'

class Notice {
    constructor() {

    }
    
    create({element: element}) {
        this.notice = {
            element: element,
            closer: element.querySelector('button[data-closer]'),
            message: element.querySelector('.message')
        }
        
        if(this.notice.closer){
            this.notice.closer.onclick = () => {
                gsap.to(this.notice.element, {
                    yPercent: 200,
                    onComplete: () => {
                        this.notice.element.remove()
                    }
                })

                if(getCookie('redirect')){ deleteCookie('redirect') }
            }
        }
    }
}

export const NoticeManager = new Notice()