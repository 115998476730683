import Page from '../../classes/Page'

import Carousel from '../../modules/Carousel'
import { app } from '../../utils/store'

export default class Take5 extends Page{
    constructor(){
        super({
            id : 'take5',
            element: '[data-app-page]',
            elements: {
                steps: '[data-carousel="steps"]'
            }
        })
    }

    create() {
        super.create()

        // Create Modules
        this.createCarousel()
    }

    // Create Galleries
    createCarousel = () => {        
        if(this.elements.steps){
            return new Carousel({ element: this.elements.steps })
        }
    }
    
    addEventListeners() {
        
    }

    // onResize() {
    //     super.onResize()
    // }

    destroy(){ 
        super.destroy()
    }
}