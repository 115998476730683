import Animation from "../classes/Animation"
import each from 'lodash/each'
import gsap from 'gsap'
import SplitText from "gsap/SplitText"

gsap.registerPlugin(SplitText);

import { app } from '../utils/store'
import { BOUNCING } from "../utils/easings";

export default class Title extends Animation {
    constructor({ element }){
        super({
            element
        })

        this.original = this.element.innerHTML

        this.element.innerHTML = this.element.innerHTML.replaceAll('\n', '<br>')

        new SplitText(this.element, {tag: 'span', type: 'words, chars'})

        this.element.innerHTML = this.element.innerHTML.replace(/<br\s*\/?>/gi,'\n')

        this.animated = false
        
        this.elementWords = this.element.children
        this.elementChars = []
        
        each(this.elementWords, (word) => { 
            word.classList.add('word')

            each(word.childNodes, (char) => {
                this.elementChars.push(char)

                char.classList.add('char')
                gsap.set(char, { 
                    display: 'inline-block', 
                    yPercent: 110 
                })
            })
        })

        this.timelineIn = gsap.timeline({
            delay: .5, 
        })
    }

    animateIn(){
        if(!this.animated){
            this.timelineIn.set(this.element, {
                autoAlpha: 1,
            })

            each(this.elementChars, (char, index) =>{
                this.timelineIn.fromTo(char, {
                    display: 'inline-block',
                    yPercent: 110, 
                },{
                    delay: index * .015,
                    duration: 1,
                    yPercent: 0,
                    ease: BOUNCING,
                    clearProps: 'all',
                }, 0)
            })

            this.timelineIn.call(() => {
                this.element.innerHTML = this.original
                // this.element.style.setProperty('display','inline-flex')
            })
        }

        // this.animated = this.element.hasAttribute('data-animation-once') ? true : false
        this.animated = true
    }

    animateOut(){
        if(!this.animated){
            gsap.set(this.element, {
                autoAlpha: 0
            })
        }
    }

    onResize() {
        this.elementLines = calculate(this.elementLines)
    }
}