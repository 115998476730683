import each from 'lodash/each'

import Page from '../../classes/Page'
import { app } from '../../utils/store'

export default class CheckoutView extends Page{
    constructor(){
        super({
            id : 'checkout',
            element: '[data-app-page]',
            elements: {
                form: '[data-form-checkout]',
                login: '[data-checkout-login]',
            }
        })
    }

    create(){
        super.create()

        if(this.elements.form){ this.createForm() }
        if(this.elements.login){ this.createLogin() }

    }

    createForm(){
        this.form = {
            checked: this.elements.form.querySelector('input[name="billing_type"]:checked'),
            type: this.elements.form.querySelector('input[name="billing_type"]'),
            professional: this.elements.form.querySelector('.input-container.billing-professional'),
            private: this.elements.form.querySelector('.input-container.billing-private'),
        }

        this.inputs = {
            professional: this.element.querySelectorAll('.input-container.billing-professional'),
            private: this.element.querySelectorAll('.input-container.billing-private'),
        }

        this.validation(this.elements.form)
    }

    createLogin(){
        app.root.style.setProperty('--login-toggler-height', `${this.elements.login.querySelector('.woocommerce-form-login-toggle').clientHeight}px`)
        app.root.style.setProperty('--login-height', `${this.elements.login.querySelector('.checkout_login').clientHeight + 1}px`)

        
        this.elements.login.querySelector('.woocommerce-form-login-toggle').onclick = () => {
            this.elements.login.querySelector('.checkout_login').style.setProperty('visibility','visible')
            this.elements.login.classList.toggle('login--open')
        }
    }

    addEventListeners() {
    }

    validation(entries){
        // On Load
        if(this.form.checked == this.element.querySelector('input#billing_type_billing_professional')){
            this.element.querySelector('input#billing_type_billing_private').removeAttribute('checked')
            each(this.form.professional, (field) => { field.classList.remove('boolean--hidden') })
            each(this.form.private, (field) => { field.classList.add('boolean--hidden') })
        } else if(this.form.checked == this.element.querySelector('input#billing_type_billing_private')){
            this.element.querySelector('input#billing_type_billing_professional').removeAttribute('checked')
            each(this.form.private, (field) => { field.classList.remove('boolean--hidden') })
            each(this.form.professional, (field) => { field.classList.add('boolean--hidden') })
        }

        // On Click
        each(entries, (input) => {
            if(input.type == 'radio'){
                input.addEventListener('click', (event) => {
                    let isBillingType = event.target.getAttribute('name') == 'billing-type',
                    isProfessional = event.target.id == 'billing_type_billing_professional',
                    isPrivate = event.target.id == 'billing_type_billing_private'
                    
                    let checked = this.element.querySelector('input#'+event.target.id+':checked')
                    
                    if(isProfessional){
                        this.element.querySelector('input#billing_type_billing_private').removeAttribute('checked')
                        each(this.inputs.professional, (field) => { field.classList.remove('boolean--hidden') })
                        each(this.inputs.private, (field) => { field.classList.add('boolean--hidden') })
                    }

                    if(isPrivate){
                        this.element.querySelector('input#billing_type_billing_professional').removeAttribute('checked')
                        each(this.inputs.private, (field) => { field.classList.remove('boolean--hidden') })
                        each(this.inputs.professional, (field) => { field.classList.add('boolean--hidden') })
                    }
                })
            }
        })
    }
}