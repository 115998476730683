import Animation from "./Animation"
import { DotLottie } from '@lottiefiles/dotlottie-web';
// import * as LottieInteractivity from '@lottiefiles/lottie-interactivity';

export default class Lottie extends Animation {
    constructor({ element, elements }){
        super({
            element,
            elements
        })

        this.createLottie(this.element)
    }

    createLottie = (entry) => {
        // let src = entry.load(entry.getAttribute('data-src'))
        // entry.load(entry.setAttribute('src',src))

        this.lottie = new DotLottie({
            autoplay: true,
            loop: true,
            canvas: entry,
            fit: entry.hasAttribute('fit') ? entry.getAttribute('fit') : 'fit-width',
            src: entry.getAttribute('src'), // or .json file
        });
        // entry.setLooping(true)
    }

    animateIn(){
        // this.element.play()
    }

    animateOut(){
        // this.element.seek(0)
        // this.element.pause()
    }
}