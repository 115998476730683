export function setCookie(cname, cvalue, expiresDays){
      var date = getDaysTime(expiresDays);
      var expires = "expires="+ date;
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      var expires = ";expires=" + date;
      var cvalue = "";
      document.cookie = cname + "=" + cvalue + expires + "; path=/";
}

export function getCookie(cname){
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
      }
      return "";
}

function getDaysTime(days){
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      return date.toUTCString()
}