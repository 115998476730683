import Dropdown from 'bootstrap/js/src/dropdown'

class Dropdowns{
    create(entry){
        new Dropdown(entry)

        if(entry.id == 'user-toggler'){
            entry.nextElementSibling.onclick = (event) => { event.stopPropagation() } 
        }
    }
}

export const DropdownManager = new Dropdowns()