import EventEmitter from 'events'
import { each, map } from 'lodash'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import { app, menu } from '../utils/store'
import { clamp, lerp } from '../utils/math'

import AsyncLoad from './AsyncLoad'
import { ScrollManager } from './Scroll'

gsap.registerPlugin(ScrollTrigger)
export default class Page extends EventEmitter {
    constructor({
        element,
        elements,
        id
    }){
        super()

        this.selector = element
        this.selectorChildren = {
            ...elements,
            animationsTitles: '[data-animation="title"]',
            animationsParagraphs: '[data-animation="paragraph"]',

            preloaders: 'img[data-src]'
        }
        
        this.id = id
    }

    create(){
        app.current = this.id
        
        this.element = document.querySelector(this.selector)
        this.elements = {}

        this.scroll = {
            current: 0,
            target: 0,
            last: 0,
            limit: 0
        }

        each(this.selectorChildren, (entry, key) =>{
            if(entry instanceof window.HTMLElement || entry instanceof window.NodeList || Array.isArray(entry)){ // if is an element or an Array of the document
                this.elements[key] = entry
            } else {
                this.elements[key] = document.querySelectorAll(entry)

                if(this.elements[key].length === 0){
                    this.elements[key] = null
                } else if(this.elements[key].length === 1){
                    this.elements[key] = document.querySelector(entry)
                }
            }
        })

        app.html.scrollTop = 0
        app.root.scrollTop = 0
        
        if(!app.loading){ 
            if(this.elements.preloaders){ this.createPreloaders() }
            // ScrollManager.create()
        } else {
            // ScrollManager.create()
            // app.preloader.once('completed', this.createAnimations)
        }
    }

    createAnimations = () => {
        app.animations = new Array()

        // Titles
        this.animationsTitles = map(this.animated.titles, (element) => {
            return new Title({ element })
        })
        app.animations.push(...this.animationsTitles)

        // Excerpts
        this.animationsExcerpts = map(this.animated.excerpts, (element) => {
            return new Excerpt({ element })
        })
        app.animations.push(...this.animationsExcerpts)

        // Images Inside Title
        this.animationsImagesInsideTexts = map(this.animated.imagesInsideText, (element) => {
            return new ImagesInsideText({ element })
        })
        app.animations.push(...this.animationsImagesInsideTexts)
    }

    createPreloaders = () => {
        if(this.elements.preloaders instanceof window.NodeList){
            this.preloaders = map(this.elements.preloaders, element => {
                return new AsyncLoad({ element })
            })
        } else {
            var element = this.elements.preloaders
            this.preloaders = new AsyncLoad({ element })
        }
    }

    /*
    ** Animation
    */
    async show() {
        return new Promise(resolve => {
            this.animationIn = gsap.timeline({ duration: 1 })

            this.animationIn.fromTo(this.element, {
                autoAlpha: 0,
            },{
                autoAlpha: 1,
                onStart: () => {
                    window.document.dispatchEvent(new Event("DOMContentLoaded", {
                        bubbles: true,
                        cancelable: true
                    }))
                }
            })
            
            this.animationIn.call(() => {
                this.addEventListeners()
                app.html.style.pointerEvents = 'auto'
                app.html.style.cursor = 'auto'
                resolve()
            })
        })
    }

    async hide() {
        return new Promise(resolve => {
            this.destroy()
            
            this.animationOut = gsap.timeline()
            
            this.animationOut.to(this.element, {
                autoAlpha: 0,
                onStart: () => {
                    ScrollManager.scrollToY(0)
                },
                onComplete: () => {
                    each(ScrollTrigger.getAll(), trigger => trigger.kill())
                }
            })

            this.animationOut.call(() => {
                this.addEventListeners()
                app.html.scrollTop = 0
                app.root.scrollTop = 0
                window.scrollTop = 0
                app.html.style.cursor = 'progress'
                app.html.style.pointerEvents = 'none'
                resolve()
            })
        })
    }

    /*
    ** Events
    */
    onResize() {
        each(this.animations, (animation) => {
            animation.onResize()
        })
    }

    /*
    ** Listeners
    */ 
    addEventListeners () {
        // window.addEventListener('mousewheel', this.onMouseWheelEvent)
    }

    removeEventListeners () {
        // window.removeEventListener('mousewheel', this.onMouseWheelEvent)
    }

    /* Destroy */ 
    destroy = () => {
        this.removeEventListeners()

        if(menu.isOpen){
            app.root.classList.remove('navigation--open')
            menu.toggler.classList.remove('active')
            menu.isOpen = false
        }
    }
}