import Page from '../../classes/Page'
import { app } from '../../utils/store'

import each from 'lodash/each'

import Carousel from '../../modules/Carousel'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import '@lottiefiles/lottie-player';
import { create as lottie } from '@lottiefiles/lottie-interactivity';

gsap.registerPlugin(ScrollTrigger)

export default class SingleProduct extends Page {
    constructor() {
        super({
            id: 'single_product',
            element: '[data-app-page]',
            elements: {
                wrapper: '[data-wrapper]', 
                sprite: '#product-sheet figure[spritesheet]',
                collaterals: '[data-product-collaterals]',
                actions: '#product-actions',
                quantity: 'input[type="number"][name="quantity"]',
                variations: '.variations_form',
                gallery: '[data-carousel="gallery"]',
                related: '[data-carousel="related"]'
            }
        })
    }

    create() {
        super.create()
        if(this.elements.sprite){ this.createSpritesheet(this.elements.sprite) }
        if(this.elements.variations){ this.createSelectVariations() }
        if(this.elements.gallery || this.elements.related ){ this.createCarousel() }
        if(this.elements.quantity){ this.createQuantity() }
    }

    createSpritesheet(entry) {
        let sprite = {
            element: entry,
            src: entry.getAttribute('spritesheet'),
        }

        let image = new Image()
        image.src = sprite.src
        image.id = `spritesheet-${sprite.element.id}`
        sprite.element.append(image)

        image.onload = () => {
            sprite.image = document.getElementById(image.id)
            sprite.frames = parseInt(image.clientWidth / image.clientHeight)

            sprite.animation = gsap.timeline({ 
                defaults: { ease: `steps(${sprite.frames - 1})` },
                paused: true,
            })
    
            sprite.animation.to(sprite.image, {
                x: (-sprite.image.clientHeight * (sprite.frames - 1)),
            })

            ScrollTrigger.create({
                scroller: this.elements.wrapper,
                trigger: this.elements.collaterals,
                animation: sprite.animation,
                start: `top top`,
                end: `bottom bottom`,
                scrub: .75,
                // markers: true
            })
            // console.log(sprite, sprite.image.clientWidth,sprite.image.clientHeight)
        }
    }

    // Create Galleries
    createCarousel = () => {

        if (this.elements.gallery) {
            this.gallery = new Carousel({ element: this.elements.gallery })
        }
        
        if (this.elements.related) {
            console.log(this.elements.related)
            this.related = new Carousel({ element: this.elements.related })
        }
    }

    createSelectVariations = () => {
        let select = this.elements.variations.querySelectorAll('select')

        each(select, (variation) => {
            let options = variation.children
            let container = document.createElement('li')
            container.classList.add('options')
            container.classList.add(variation.id)
            
            each(options, (option, index) => {
                if(index != 0){
                    let button = document.createElement('button')
                    button.classList.add('btn-neutral')
                    button.value = option.value
                    button.innerHTML = `<span>${option.innerText}</span>`
                    container.append(button)
                    
                    button.onclick = (event) => {
                        event.preventDefault()
                        options[index].checked = true
                        // variation.querySelector(`option[value="${button.value}"]`).selected = 'selected'
                        variation.querySelector(`option[value="${button.value}"]`).click()
                    }
                }
            })

            this.elements.actions.prepend(container)
            console.log(options)
        })
    }

    createQuantity = () => {
        this.elements.quantity.onfocus = (event) => {
            this.elements.quantity.value = ''
        }
    }

    onResize() {
        super.onResize()
    }

    destroy() {
        super.destroy()
    }
}