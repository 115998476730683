import each from 'lodash/each'

import Page from '../../classes/Page'
import { app } from '../../utils/store'

export default class CartView extends Page{
    constructor(){
        super({
            id : 'cart',
            element: '[data-app-page]',
            elements: {
                sidebar: '[data-sidebar]',
                coupon: '[data-coupon]',
            }
        })
    }

    create() {
        super.create()

        if(this.elements.sidebar){ this.createSidebar() }
        if(this.elements.coupon){ this.createCoupon() }
    }

    createSidebar(){
        app.root.style.setProperty('--sidebar-width', `${this.elements.sidebar.clientWidth}px`)
    }

    createCoupon(){
        app.root.style.setProperty('--coupon-toggler-height', `${this.elements.coupon.querySelector('.woocommerce-form-coupon-toggle').clientHeight}px`)
        app.root.style.setProperty('--coupon-height', `${this.elements.coupon.querySelector('.coupon').clientHeight}px`)
    }
    
    addEventListeners() {
        window.addEventListener('resize', () => {
            this.createSidebar()
            this.createCoupon()
        })
    }

    onResize() {
        super.onResize()
    }

    destroy(){ 
        super.destroy()
    }
}