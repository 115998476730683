import Component from '../classes/Component'

import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay, EffectCards, Mousewheel, FreeMode } from 'swiper/modules'

export default class Carousel extends Component {
    constructor({ element }) {
        super({element: element})
    }

    create(){
        super.create()

        if(this.element){
            this.createCarousel()
        }
    }

    createCarousel() {
        let version = this.element.getAttribute('data-carousel')
        
        if (version == 'chart') {
            this.carousel = new Swiper(this.element, {
                modules: [Pagination, Navigation, Autoplay],
                autoplay: {
                    delay: 3000,
                },
                speed: 5000,
                autoHeight: true,
                slidesPerView: 1,
                loop: true,
                // centeredSlides: false,
                // spaceBetween: 20,
                direction: "vertical",
                // initialSlide: 1,
                // If we need pagination
                navigation: {
                    nextEl: this.element.parentNode.querySelector("[data-next]"),
                    prevEl: this.element.parentNode.querySelector("[data-prev]"),
                    clickable: true,
                },
                // on: {
                //     init: (carousel) => {
                //         if(carousel.activeIndex == 0){
                //             carousel.navigation.nextEl.classList.add('active')
                //         } else {
                //             carousel.navigation.nextEl.classList.remove('active')
                //         } 
                //     },
                //     activeIndexChange: (carousel) => {
                //         if(carousel.activeIndex == 0){
                //             carousel.navigation.nextEl.classList.add('active')
                //         } else {
                //             carousel.navigation.nextEl.classList.remove('active')
                //         }
                //     }
                // }
            })
        }

        if (version == 'steps') {
            this.carousel = new Swiper(this.element, {
                modules: [Pagination, Navigation, Autoplay],
                // effect: 'cards',
                // cardsEffect: {
                //     perSlideOffset: 8,
                //     perSlideRotate: 2,
                //     rotate: true
                // },
                // grabCursor: true,
                // autoplay: {
                //     delay: 3000,
                // },
                // speed: 500,
                loop: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                spaceBetween: 0,
                // autoHeight: true,
                // initialSlide: 1,
                // If we need pagination
                navigation: {
                    nextEl: this.element.parentNode.querySelector("[data-next]"),
                    prevEl: this.element.parentNode.querySelector("[data-prev]"),
                    clickable: true,
                },
                // breakpoints: {
                //     768: {
                //         slidesPerView: 'auto',
                //     }
                // }
                // on: {
                //     init: (carousel) => {
                //         if(carousel.activeIndex == 0){
                //             carousel.navigation.nextEl.classList.add('active')
                //         } else {
                //             carousel.navigation.nextEl.classList.remove('active')
                //         } 
                //     },
                //     activeIndexChange: (carousel) => {
                //         if(carousel.activeIndex == 0){
                //             carousel.navigation.nextEl.classList.add('active')
                //         } else {
                //             carousel.navigation.nextEl.classList.remove('active')
                //         }
                //     }
                // }
            })
        }

        if(version == 'gallery'){
            this.carousel = new Swiper(this.element, {
                modules: [Autoplay, Navigation, Pagination, Mousewheel],
                speed: 500,
                slidesPerView: 'auto',
                centeredSlides: false,
                spaceBetween: 0,
                // initialSlide: 1,
                navigation: {
                    nextEl: this.element.querySelector("[data-next]"),
                    prevEl: this.element.querySelector("[data-prev]"),
                    clickable: true,
                },
                pagination: {
                    type: "bullets",
                    el: this.element.querySelector("[data-pagination]"),
                    clickable: true,
                    dynamicBullets: true,
                },
                scrollbar: {
                    el: this.element.querySelector("[data-scrollbar]"),
                    draggable: true,
                },
                mousewheel: {
                    enabled: true,
                    forceToAxis: true
                },
                // autoplay: {
                //     delay: 3000,
                // },
                centerInsufficientSlides: true,
                breakpoints: {
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                        centeredSlides: false,
                    },
                },
                autoHeight: false,
            })
        }

        if (version == 'stories') {
            this.carousel = new Swiper(this.element, {
                modules: [Pagination, Navigation, Autoplay, FreeMode, Mousewheel],
                // effect: 'cards',
                // cardsEffect: {
                //     perSlideOffset: 8,
                //     perSlideRotate: 2,
                //     rotate: true
                // },
                // grabCursor: true,
                autoplay: {
                    delay: 3000,
                },
                speed: 500,
                loop: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                spaceBetween: 0,
                loop: true,
                // autoHeight: true,
                // initialSlide: 1,
                // If we need pagination
                navigation: {
                    nextEl: this.element.parentNode.querySelector("[data-next]"),
                    prevEl: this.element.parentNode.querySelector("[data-prev]"),
                    clickable: true,
                },
                freeMode: {
                    enabled: true,
                    sticky: true
                },
                mousewheel: {
                    enabled: true,
                    forceToAxis: true,
                }
                // breakpoints: {
                //     768: {
                //         slidesPerView: 'auto',
                //     }
                // }
                // on: {
                //     init: (carousel) => {
                //         if(carousel.activeIndex == 0){
                //             carousel.navigation.nextEl.classList.add('active')
                //         } else {
                //             carousel.navigation.nextEl.classList.remove('active')
                //         } 
                //     },
                //     activeIndexChange: (carousel) => {
                //         if(carousel.activeIndex == 0){
                //             carousel.navigation.nextEl.classList.add('active')
                //         } else {
                //             carousel.navigation.nextEl.classList.remove('active')
                //         }
                //     }
                // }
            })
        }

        if(version == 'related'){
            this.carousel = new Swiper(this.element, {
                modules: [Autoplay, Navigation, Pagination, Mousewheel],
                speed: 500,
                slidesPerView: 'auto',
                centeredSlides: false,
                spaceBetween: 0,
                // initialSlide: 1,
                navigation: {
                    nextEl: this.element.querySelector("[data-next]"),
                    prevEl: this.element.querySelector("[data-prev]"),
                    clickable: true,
                },
                pagination: {
                    type: "bullets",
                    el: this.element.querySelector("[data-pagination]"),
                    clickable: true,
                    dynamicBullets: true,
                },
                scrollbar: {
                    el: this.element.querySelector("[data-scrollbar]"),
                    draggable: true,
                },
                mousewheel: {
                    enabled: true,
                    forceToAxis: true
                },
                // autoplay: {
                //     delay: 3000,
                // },
                centerInsufficientSlides: true,
                breakpoints: {
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                        centeredSlides: false,
                    },
                },
                autoHeight: false,
            })
        }
    }
}