import jQuery from 'jquery'
import { each, map } from 'lodash'

// App Store
import { app, menu } from './utils/store'

// User Detect
import { DetectionManager, Credits } from './utils/detect'

// Navigation
import { Navigation, Footer} from './modules/Navigation'

// Preloader
import Preloader from './modules/Preload'

// Classes::Managers
// import { CursorManager } from './modules/Cursor'
import { AccordionManager } from './classes/Accordions'

import { ButtonManager } from './classes/Buttons'
import { DropdownManager } from './classes/Dropdowns'
import { ScrollManager } from './classes/Scroll'
import { NoticeManager } from './modules/Notice'

// AsyncLoad
import AsyncLoad from './classes/AsyncLoad'
import Video from './classes/Video'
import Lottie from './classes/DotLottie'

// Modules
import Form from './modules/Forms'
 
// Pages
import Home from './pages/Home'
import About from './pages/About'
import Take5 from './pages/Take5'
import SeekHelp from './pages/SeekHelp'
import ArchiveStory from './pages/Stories/index'
import SingleStory from './pages/Stories/single'

import AccountView from './pages/Ecommerce/account'
import CartView from './pages/Ecommerce/cart'
import CheckoutView from './pages/Ecommerce/checkout'
import LoginView from './pages/Ecommerce/login'

import ArchiveProduct from './pages/Product/index'
import SingleProduct from './pages/Product/single'

// Ecommerce
import { Sidebar } from './modules/Sidebar'
import { Cart } from './modules/Cart'

// Animations
import Title from './animations/Title'
// import Paragraph from './animations/Paragraph'
// import Excerpt from './animations/Excerpt'
import ImagesInsideText from './animations/ImagesInsideText'
import Card from './animations/Card'

class App {
    constructor(){
        app.init = true
        app.root = document.body
        app.viewport = document.querySelector('[data-app-viewport]')

        //Async Load
        app.images = document.querySelectorAll('[data-src][data-webp]')
        app.videos = document.querySelectorAll('video')

        // Cursor::Only if is Desktop
        // if(app.device.desktop){ CursorManager.create() }

        app.create = {
            content: this.createContent.bind(this),
            navigation: this.createNavigation.bind(this),
            buttons: this.createButtons.bind(this),
            preloaders: this.createPreloaders.bind(this),
            pages: this.createPages.bind(this),
            accordions: this.createAccordions.bind(this),
            dropdowns: this.createDropdowns.bind(this),
            sidebar: this.createSidebar.bind(this),
            cart: this.createCart.bind(this),
            animation: this.createAnimations.bind(this),
            lotties: this.createLotties.bind(this),
            notices: this.createNotices.bind(this),
        }

        map(app.create, (callback) => { return callback() })

        this.addEventListeners()
        // this.addLinkListeners()
        console.log(app)
    }

    // Create Content
    createContent = () => {
        this.content = document.querySelector('[data-app-content]')
        this.template = this.content.getAttribute('data-template')

        this.wrapper = document.querySelector('[data-wrapper]')

        this.entrypoint = document.querySelector('[data-menu] .nav-item')

        this.dropdown = {
            togglers: document.querySelectorAll('[data-bs-toggle="dropdown"]'),
        }

        this.accordion = {
            togglers: document.querySelectorAll('.accordion-button'),
        }

        this.notices = document.querySelectorAll('.notice--error')
        
        this.modal = {
            elements: document.querySelectorAll('[data-modal]'),
            togglers: document.querySelectorAll('[data-modal-target]'),
            closers: document.querySelectorAll('[data-modal-close]')
        }

        this.sliders = document.querySelectorAll('.slider')

        this.anchors = document.querySelectorAll('a[href*="#"]:not([href="#"])')
        this.carousels = document.querySelectorAll('[data-carousel]')
        this.header = document.querySelector('header')
        this.forms = document.querySelectorAll('form')
        this.filters = document.getElementById('filters')

        // Ecommerce
        this.cart = document.querySelector('[data-minicart]')
        this.sidebar = document.querySelector('[data-sidebar]')

        app.current = this.template
        app.wrapper = this.wrapper

        this.animated = {
            titles: document.querySelectorAll('[data-animation="title"]'),
            // paragraphs: document.querySelectorAll('[data-animation="paragraph"]'),
            // excerpts: document.querySelectorAll('[data-animation="excerpt"]'),
            separators: document.querySelectorAll('span.separator'),
            lotties: document.querySelectorAll('[data-lottie]'),
            cards: document.querySelectorAll('[data-animation="card"]')
        }

        // console.log(this, app)
    }

    // Create Navigation
    createNavigation = () => {
        app.navigation = new Navigation()
        app.footer = new Footer()
    }

    // Create Navigation
    createButtons = () => {
        this.buttons = document.querySelectorAll('.btn-default, .btn-neutral, button[type="submit"]')
        map(this.buttons, (button) => ButtonManager.create({ element: button }))
    }

    // Create Preloaders
    createPreloaders = () => {
        // if(!sessionStorage.getItem('init')){ 
            app.preloader = new Preloader()
            app.preloader.once('completed', this.onPreloaded)
        // } else {
            // this.onPreloaded()
        // }
    }

    // Create Pages
    createPages = () => {
        this.pages = {
            // Ecommerce
            account: new AccountView(),
            cart: new CartView(),
            checkout: new CheckoutView(),
            login: new LoginView(),
            // Products
            shop: new ArchiveProduct(),
            single_product: new SingleProduct(),
            // Pages
            home: new Home(),
            about: new About(),
            take5: new Take5(),
            seekhelp: new SeekHelp(),
            archive_story: new ArchiveStory(),
            single_story: new SingleStory(),
            // contacts: new Contacts(),
            // help_center: new HelpCenter(),
            // policy: new Policy(),
        }
        
        this.page = this.pages[this.template]
        app.page = this.page

        console.log(app.page)

        // ScrollManager.create()

        if(this.page){
            this.page.create()
            // console.log(app.page)
        }
    }

    // Create Sidebar
    createSidebar = () => {
        if(this.sidebar){
            app.sidebar = new Sidebar()
        }
    }

    // Create Cart
    createCart = () => {
        if(this.cart){
            app.cart = new Cart()
        }
    }

    // Create Sliders
    createSliders = () => {
        if(this.sliders){
            each(this.sliders, (slider) => {
                return new Sliders({ element: slider })
            })
        }
    }

    // Create Accordions
    createAccordions = () => {
        each(this.accordion.togglers, (accordion) => AccordionManager.create(accordion))
    }

    // Create Dropdowns
    createDropdowns = () => {
        each(this.dropdown.togglers, (dropdown) => DropdownManager.create(dropdown))
    }

    // Create Animations
    createAnimations() {
        app.animations = new Array()

        // Titles
        this.animationsTitles = map(this.animated.titles, (element) => {
            return new Title({ element })
        })
        app.animations.push(...this.animationsTitles)

        // // Paragraphs
        // this.animationsParagraphs = map(this.animated.paragraphs, (element) => {
        //     return new Paragraph({ element })
        // })
        // app.animations.push(...this.animationsParagraphs)

        // // Excerpts
        // this.animationsExcerpts = map(this.animated.excerpts, (element) => {
        //     return new Excerpt({ element })
        // })
        // app.animations.push(...this.animationsExcerpts)

        // Images Inside Title
        this.animationsImagesInsideTexts = map(this.animated.imagesInsideText, (element) => {
            return new ImagesInsideText({ element })
        })
        app.animations.push(...this.animationsImagesInsideTexts)

        // Cards
        this.animationsCards = map(this.animated.cards, (element) => {
            return new Card({ element })
        })
        app.animations.push(...this.animationsCards)
    }

    // Create Lotties
    createLotties() {
        this.illustrations = new Array()

        this.illustrationsEntries = map(this.animated.lotties, (element) => {
            return new Lottie({ element })
        })
        this.illustrations.push(...this.illustrationsEntries)
    }

    // Create Notices
    createNotices = () => {
        each(this.notices, (notice) => NoticeManager.create({element: notice}))
    }

    /*
    ** Events
    */

    // On Loaded
    onPreloaded() {
        app.loading = false
        app.html.classList.remove('loading')
        app.html.classList.remove('scroll--off')

        this.preloaders = each(app.images, image => {
            return new AsyncLoad({ element: image })
        })

        this.videos = each(app.videos, video => {
            return new Video({ element: video })
        })
        
        if(app.preloader){ app.preloader.destroy() }
        this.onResize
    }

    // On Added To Cart
    onAddedToCart(){
        console.log('Aggiunto')
        this.createButtons()
    }

    // On Page Resize
    onResize(){
        if(this.page && this.page.onResize){
            this.page.onResize()
        }
    }

    // On Pop State
    onPopState(){
        this.onChange({
            url: window.location.pathname,
            push: false
        })
    }

    // // On Page Change
    async onChange({url, push = true}) {
        await this.page.hide() // Hide the current page

        const request = await window.fetch(url)

        if (request.status === 200) {
            let response = await request.text()
            response = (response
                .replace('<body','<fragment'))
                .replace('body>','fragment>')

            // Set request Result
            this.request = {
                html: response,
                fragment: document.createElement('fragment')
            }

            this.request.fragment.innerHTML = this.request.html
            // Get the New Body Node
            this.request.body = {
                classes: this.request.fragment.querySelector('fragment').classList,
                dataset: this.request.fragment.querySelector('fragment').dataset,
                content: this.request.fragment.querySelector('[data-app-content]')
            }
            // Get the Language Switcher
            this.request.navigation = this.request.fragment.querySelector('[data-navigation]')
            this.request.language = {
                nav: this.request.fragment.querySelector('[data-language-dropdown]'),
            }

            // Set the new URL
            if (push) { window.history.pushState({}, '', url) }
            
            // Set classes to the body
            document.body.className = ''
            document.body.className = this.request.body.classes.value
            // Set attributes to the body
            map(Object.entries(this.request.body.dataset), (attribute) => {
                document.body.setAttribute(`data-${attribute[0]}`,attribute[1])
            })
            
            // Set the page template
            this.template = this.request.body.content.getAttribute('data-template')
            this.content.setAttribute('data-template', this.template)

            // Set the new content HTML
            this.content.innerHTML = this.request.body.content.innerHTML
            
            // Set the current language switcher
            app.nav.querySelector('[data-language-dropdown]').innerHTML = this.request.language.nav.innerHTML
            app.nav.innerHTML = this.request.navigation.innerHTML
            
            
            this.page = this.pages[this.template]
            this.page.create()
            
            this.createContent()
            this.createNavigation()
            this.createAnimations()
            this.addLinkListeners()

            CursorManager.update()
            
            document.body.scrollTop = 0

            this.onResize()
            this.page.show()
        } else {
            console.error(request.error)
        }
    }

    /*
    ** Listeners
    */
    addEventListeners() {
        // window.addEventListener('popstate', this.onPopState.bind(this))
        window.addEventListener('resize', this.onResize)

        // if(app.filter){
        //     app.filters.on('complete', this.addLinkListeners.bind(this))
        // }
    }

    addLinkListeners() {
        app.links = document.querySelectorAll('a[data-async]')

        each(app.links, (link) =>{
            link.onclick = (event) => {
                event.preventDefault()
                
                app.init = false

                const { href } = link

                this.onChange({ url: href })
            }
        })
    }
}

new App()
new Credits()
