import map from 'lodash/map'
// import formatAspectRatio from 'aspect-ratio';

import { app } from './store';

export function injectScript(src, {id = null, insertBefore = null, target = null}){
	return new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = src
		if(id){ script.id = id }
		script.async = false
		script.addEventListener('load', resolve)
		script.addEventListener('error', e => reject(e.error))
		// Target
		if(target && insertBefore){
			target.parentNode.insertBefore(script, target)
		} else {
			target ? target.append(script) : document.body.append(script)
		}
	})
}

export function clone(element){
	var clonedElement = element.cloneNode(true);
	clonedElement.classList.add('cloned');
	element.parentNode.append(clonedElement);
}

// export function ratio(element){
// 	return formatAspectRatio(element.naturalWidth, element.naturalHeight);
// }

export const findAncestor = (element, selector) => {
	while ((element = element.parentElement) && !((element.matches || element.matchesSelector).call(element, selector))) { return element }
}

export const getOffset = (element, scroll = 0) => {
	const box = element.getBoundingClientRect()

	return {
		bottom: box.bottom,
		height: box.height,
		left: box.left,
		top: box.top + scroll,
		width: box.width
	}
}

export function getIndex(node) {
	let index = 0

	while ((node = node.previousElementSibling)) {
		index++
	}

	return index
}

export function mapEach(element, callback) {
	if (element instanceof window.HTMLElement) {
		return [callback(element)]
	}

	return map(element, callback)
}

// Gets the mouse position
export const mouseCurrentPosition = (event) => {
    return { 
        x : event.clientX, 
        y : event.clientY 
    };
};

export const easing = `cubic-bezier(0.19, 1, 0.22, 1)`
