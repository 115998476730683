import { each } from 'lodash'
import { app } from '../utils/store'


import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollSmoother from 'gsap/ScrollSmoother'

import Lenis from '@studio-freight/lenis'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

class Scroll {
    constructor() { app.scroll = this }

    create = () => {
        // app.virtualscroll = ScrollSmoother.create({
        //     wrapper: '[data-app-page]',
        //     content: '[data-wrapper]'
        // })

        // Accordion Fix
        // each(document.querySelectorAll('.accordion-item'), (accordion) => {
        //     accordion.addEventListener('shown.bs.collapse', () => { ScrollTrigger.refresh() })
        //     accordion.addEventListener('hidden.bs.collapse', () => {  ScrollTrigger.refresh() })
        // })

        // console.log(app.wrapper != document.documentElement, app.wrapper, app.wrapper.parentNode)

        // if(app.wrapper != document.documentElement){
        //     app.virtualscroll = new Lenis({
        //         wrapper: app.wrapper.parentNode,
        //         content: app.wrapper
        //     })
        // } else {
            app.virtualscroll = new Lenis({
                duration: 1.2,
                easing: (t) => Math.min(1, 1.0001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
                orientation: 'vertical', // vertical, horizontal
                gestureOrientation: 'vertical', // vertical, horizontal, both
                smoothWheel: true,
                wheelMultiplier: 1,
                smoothTouch: false,
                touchMultiplier: 1,
                infinite: false,
            })
        // }
 
        //get scroll value
        // app.virtualscroll.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
        //     console.log({ scroll, limit, velocity, direction, progress })
        // })
        
        function raf(time) {
            app.virtualscroll.raf(time)
            requestAnimationFrame(raf)
        }
        
        requestAnimationFrame(raf)

        window.document.dispatchEvent(new Event("resize", {
            bubbles: true,
            cancelable: true
        }))
    }

    scrollBehaviour = (entry) => {
        entry.onclick = (event) => {
            var target = document.querySelector(event.target.hash),
                offset = target.offsetTop

            if(entry.classList.contains('nav-link')){
                each(document.querySelectorAll('.quick-menu .nav-link'), (link) => link.classList.remove('active') )
                entry.classList.add('active')
            }

            event.preventDefault()
            gsap.to(window, {
                duration: gsap.utils.mapRange(0, document.body.scrollHeight, 0, 2.5, offset),
                scrollTo: offset - (target.clientHeight / 10),
                ease: 'Power2.easeOut',
            })
        }
    }

    scrollToY = (entry, { options } = false) => {
        app.virtualscroll.scrollTo(entry, { options });
    }
}

 export const ScrollManager = new Scroll()
