import Component from "../classes/Component"
import each from 'lodash/each'

import { app, menu, navigation } from '../utils/store'

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export class Navigation extends Component {
    constructor(){
        super({
            element: '[data-navigation]',
            elements: {
                main: '[data-navigation-main] [data-main]',
                actions: document.querySelector('[data-main-actions]'),
                dropdowns: 'dropdown-menu',
                toggler: '[data-menu-toggler]',
                search: 'form[role="search"]',
                breadcrumbs: document.querySelector('[data-breadcrumbs]')
            }
        })
    }
    
    create(){
        super.create()
        
        app.nav = this.element
        app.root.style.setProperty('--window-height', `${window.innerHeight}px`)
        app.root.style.setProperty('--window-width', `${window.innerWidth}px`)
        
        app.root.style.setProperty('--viewport-height', `${window.visualViewport.height}px`)
        
        app.root.style.setProperty('--navigation-height', `${app.nav.clientHeight}px`)

        if(this.elements.actions){
            app.root.style.setProperty('--actions-height', `${this.elements.actions.clientHeight}px`)
        }

        if(this.elements.search){
            app.root.style.setProperty('--search-submit-height', `${this.elements.search.querySelector('button').clientHeight}px`)
        }

        if(this.elements.breadcrumbs){
            app.root.style.setProperty('--breadcrumbs-height', `${this.elements.breadcrumbs.clientHeight}px`)
        }

        if(this.elements.toggler){
            this.elements.toggler.onclick = (event) => this.onClick(event)
        }
    }
    
    addEventListeners(){
        window.addEventListener('resize', () => {
            app.root.style.setProperty('--window-height', `${window.innerHeight}px`)
            app.root.style.setProperty('--window-width', `${window.innerWidth}px`)
            app.root.style.setProperty('--navigation-height', `${app.nav.clientHeight}px`)

            if(this.elements.actions){
                app.root.style.setProperty('--actions-height', `${this.elements.actions.clientHeight}px`)
            }
    
            if(this.elements.search){
                app.root.style.setProperty('--search-submit-height', `${this.elements.search.querySelector('button').clientHeight}px`)
            }
    
            if(this.elements.breadcrumbs){
                app.root.style.setProperty('--breadcrumbs-height', `${this.elements.breadcrumbs.clientHeight}px`)
            }
        })

        window.visualViewport.addEventListener('resize', () => {
            app.root.style.setProperty('--viewport-height', `${window.visualViewport.height}px`)
        })


        window.addEventListener('scroll', () => this.onScroll())
    }

    onClick = (event) => {
        event.preventDefault()
        // app.html.classList.toggle('scroll--off')
        app.root.classList.toggle('navigation--open')
        app.root.classList.remove('menu--open')
        
        if(this.elements.dropdowns){ each(this.elements.dropdowns, (entry) => { entry.classList.remove('show') }) }
        if(menu.current){ menu.current.classList.remove('active') }
        
        if(!navigation.isOpen){
            navigation.isOpen = true
            navigation.current = this.elements.main
        } else {
            navigation.isOpen = false
            navigation.current = null
        }

        menu.isOpen = false
        menu.current = null
    }

    onScroll = () => {
        if(window.scrollY > app.nav.clientHeight){
            this.element.classList.add('navigation--active')
        } else {
            this.element.classList.remove('navigation--active')
        }
    }

    destroy = () => { /** Code your own */ }
}

export class Footer extends Component {
    constructor(){
        super({
            element: '[data-footer]',
        })
    }
    
    create(){
        super.create()
        
        app.footer = this.element
        app.root.style.setProperty('--footer-height', `${app.footer.clientHeight}px`)
    }
    
    addEventListeners(){
        window.addEventListener('resize', () => app.root.style.setProperty('--footer-height', `${app.footer.clientHeight}px`))
    }

    destroy = () => { /** Code your own */ }
}