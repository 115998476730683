import Page from '../../classes/Page'

import Carousel from '../../modules/Carousel'
import { app } from '../../utils/store'

export default class ArchiveStory extends Page{
    constructor(){
        super({
            id : 'archive_story',
            element: '[data-app-page]',
            elements: {
                cards: '[data-carousel="stories"]'
            }
        })
    }

    create() {
        super.create()

        // Create Modules
        this.createCarousel()
        console.log(this.elements.cards)    

    }

    // Create Galleries
    createCarousel = () => {   
        console.log(this.elements.cards)    
        if(this.elements.cards){
            return new Carousel({ element: this.elements.cards })
        }
    }
    
    addEventListeners() {
        
    }

    // onResize() {
    //     super.onResize()
    // }

    destroy(){ 
        super.destroy()
    }
}