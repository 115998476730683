import each from 'lodash/each'

import Page from '../../classes/Page'
import { app } from '../../utils/store'

import Form from '../../modules/Forms'

export default class AccountView extends Page{
    constructor(){
        super({
            id : 'account',
            element: '[data-app-page]',
            elements: {
                form: 'form',
            }
        })
    }

    create() {
        super.create()

        if(this.elements.sidebar){ this.createSidebar() }
        if(this.elements.form){ this.createForm() }
    }

    createSidebar(){
        app.root.style.setProperty('--sidebar-width', `${this.elements.sidebar.clientWidth}px`)
    }

    createForm(){
        if(document.getElementById('billing_type_field')){
            this.form = new Form({ element: this.elements.form })
            this.form.billingBehaviour()
        }
    }
    
    addEventListeners() {
        window.addEventListener('resize', () => {
            this.createSidebar()
        })
    }

    onResize() {
        super.onResize()
    }

    destroy(){ 
        super.destroy()
    }
}