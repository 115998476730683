import each from 'lodash/each'

import Page from '../../classes/Page'
import { app } from '../../utils/store'

export default class ArchiveProject extends Page{
    constructor(){
        super({
            id : 'archive_product',
            element: '[data-app-page]',
            elements: {
                products: 'section.results #list', 
            }
        })
    }

    create() {
        super.create()

        if(this.elements.products.children){ this.createTeases() }
    }

    createTeases(){
        each(this.elements.products.children, (tease, index) => {
            if(tease.getAttribute('data-type') == 'simple' || tease.getAttribute('data-type') == 'variable'){
                tease.style.setProperty('--summary-height', `${(tease.querySelector('.summary').clientHeight)}px`)
                tease.style.setProperty('--media-height', `${(tease.querySelector('.media').clientHeight - tease.querySelector('.info').clientHeight)}px`)
                tease.style.setProperty('--title-height', `${(tease.querySelector('.title').clientHeight)}px`)
            }
        })
    }
    
    addEventListeners() {
        window.addEventListener('resize', () => this.createTeases())
    }

    onResize() {
        super.onResize()
    }

    destroy(){ 
        super.destroy()
    }
}