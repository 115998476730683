import Component from "../classes/Component"
import { app, minicart } from '../utils/store'

import { each } from 'lodash'

import gsap from 'gsap'

export class Cart {
    constructor(){
        this.cart = {
            empty: true,
            add: {
                async: document.querySelectorAll('[data-add-cart-async]'),
                woo: document.querySelectorAll('.add_to_cart_button.ajax_add_to_cart')
            }
        }
        
        this.minicart = {
            element: document.querySelector('[data-minicart]'),
            list: document.querySelector('ul[data-minicart-products]'),
            products: document.querySelectorAll('ul[data-minicart-products] li'),
            totals: document.querySelector('[data-minicart-totals]'),
            amount: document.querySelector('[data-minicart-totals] span.amount bdi'),
            toggler: document.querySelector('[data-minicart-toggler]'),
            remove: document.querySelectorAll('[data-async-remove]')
        }
        
        this.request = { init: false }
        this.controller = new AbortController()

        this.minicart.toggler.onclick = (event) => this.onTogglerClick(event.target)

        if(this.cart.add.async){
            each(this.cart.add.async, (button) => {
                button.onclick = (event) => {
                    event.stopPropagation()
                    this.button = event.target
                    document.querySelector(`a[href="${event.target.getAttribute('data-href')}"]`).click()
                    event.stopPropagation()
                    event.stopImmediatePropagation()
                    this.addToCart(event)
                    event.target.style.pointerEvents = 'none'
                    if(this.request.init){ this.controller.abort() }
                }
            })
        }

        if(this.minicart.remove){
            each(this.minicart.remove, (button) => {
                button.onclick = (event) => this.addToCart(event)
            })
        }
    }
    
    addToCart(event){
        // console.log(this.cart.empty)
        
        if(this.animateOut){
            this.animateOut.kill()
            this.animateOut.pause(0)
        }

        this.animationOut()
    }

    onTogglerClick = (entry) => {
        app.root.classList.toggle('scroll--off')
        app.root.classList.toggle('minicart--open')
        entry.classList.toggle('minicart--open')

        this.minicart.element.classList.toggle('active')
    }

    addEventListeners(){
    }

    animationOut() {
        this.animateOut = gsap.timeline({ paused: true, overwrite: 'auto' })

        this.animateOut
            .to(this.minicart.products, {
                duration: .25,
                xPercent: -100,
                autoAlpha: 0,
                stagger: {
                    each: .15,
                    from: "top",
                    grid: "auto",
                    ease: 'linear',
                },
                onStart: () => this.minicart.toggler.classList.add('loading'),
                onComplete: () => this.minicart.element.classList.add('loading')
            }, 0)
            .to(this.minicart.amount, {
                duration: .25,
                yPercent: -100,
                autoAlpha: 0,
            }, 0)
            .to(this.minicart.toggler.querySelector('span.notify'), {
                duration: .25,
                yPercent: -100,
            }, 0)
            .to(app.root, {
                duration: .5,
                onComplete: () => {
                    this.minicartRefresh()
                }
            }, '>')

        this.animateOut.call(() => this.minicartRefresh())

        this.animateOut.play()
    }

    animationIn() {
        this.animateIn = gsap.timeline({ paused: true, overwrite: 'auto' })

        this.animateIn
            .fromTo(this.minicart.amount, {
                yPercent: 100,
                autoAlpha: 0,
            },{
                delay: .25,
                yPercent: 0, 
                autoAlpha: 1,
            }, 0)
            .fromTo(this.minicart.toggler.querySelector('span.notify'), {
                yPercent: 100,
            },{
                delay: .25,
                yPercent: 0, 
            }, 0)
            .fromTo(this.minicart.products, {
                xPercent: 120,
                autoAlpha: 0,
            },{
                delay: .25,
                xPercent: 0, 
                autoAlpha: 1,
                stagger: {
                    each: .15,
                    from: "top",
                    grid: "auto",
                    ease: 'linear',
                }
            }, 0)

        this.animateIn.call(() => {
            this.animateOut.kill()
            this.request.init = false
            this.minicart.element.classList.remove('loading')
            this.minicart.toggler.classList.remove('loading')
            this.button ? this.button.style.pointerEvents = 'auto' : null
        })
        
        this.animateIn.play()
    }

    async minicartRefresh(){
        this.request.init = true

        let request = await fetch(ecommerce.cart.endpoint, {
            signal: this.controller.signal
        })

        if (request.status === 200) {
            const html = await request.text(),
                fragment = document.createElement('fragment')

            fragment.innerHTML = html

            let productList = fragment.querySelector('ul[data-minicart-products]'),
                totals = fragment.querySelector('[data-minicart-totals]'),
                count = fragment.querySelector('ul[data-minicart-products]') ? fragment.querySelector('ul[data-minicart-products]').getAttribute('data-minicart-products') : 0

            // console.log(this.cart.empty, productList, totals, count)

            if(this.minicart.list){
                this.minicart.list.innerHTML = productList.innerHTML
                this.minicart.list.setAttribute('data-minicart-products', count)
            } else {
                this.minicart.element.querySelector('div.product-list').innerHTML = productList.outerHTML
            }
            
            this.minicart.toggler.querySelector('span.notify').innerHTML = count
            this.minicart.element.querySelector('.headings .info a').innerHTML = `${this.minicart.element.querySelector('.headings .info a').getAttribute('data-label')} (${count})`

            if(count > 0){
                this.cart.empty = false
                this.minicart.element.setAttribute('data-minicart',null)
            } else {
                this.cart.empty = true
                this.minicart.element.setAttribute('data-minicart','empty')
            }

            this.minicart.totals.innerHTML = totals.innerHTML

            this.minicart = {
                empty: false,
                element: document.querySelector('[data-minicart]'),
                list: document.querySelector('ul[data-minicart-products]'),
                products: document.querySelectorAll('ul[data-minicart-products] li'),
                totals: document.querySelector('[data-minicart-totals]'),
                amount: document.querySelector('[data-minicart-totals] span.amount bdi'),
                toggler: document.querySelector('[data-minicart-toggler]'),
                remove: document.querySelectorAll('[data-async-remove]')
            }

            if(this.minicart.remove){
                each(this.minicart.remove, (button) => {
                    button.onclick = (event) => this.addToCart(event)
                })
            }
            
            this.minicart.element.classList.remove('loading')

            this.animationIn()
        } else {
            console.log(request.error)
        }
    }

    destroy = () => { /** Code your own */ }
}