import Animation from "../classes/Animation"
import each from 'lodash/each'
import gsap from 'gsap'
import SplitText from "gsap/SplitText"

gsap.registerPlugin(SplitText);

import { app } from '../utils/store'
import { BOUNCING } from "../utils/easings";

export default class Card extends Animation {
    constructor({ element }){
        super({
            element
        })
        
        this.timelineIn = gsap.timeline()
        
        this.timelineIn.set(this.element, {
            yPercent: app.nav.clientHeight,
        })
    }

    animateIn(){
        if(!this.animated){
            this.timelineIn.fromTo(this.element, {
                yPercent: app.nav.clientHeight,
            },{
                duration: 1,
                y: 0,
                ease: BOUNCING,
                clearProps: 'all',
            })
        }

        this.animated = true
    }

    animateOut(){
        // if(!this.animated){
        //     gsap.set(this.element, {
        //         autoAlpha: 0
        //     })
        // }
    }

    onResize() {
        this.elementLines = calculate(this.elementLines)
    }
}