import Component from "../classes/Component"

import { app } from '../utils/store'

export class Sidebar extends Component {
    constructor(){
        super({
            element: '[data-sidebar]',
            elements: {
                toggler: '[data-sidebar-toggler]',
                closer: '[data-sidebar-closer]'
            }
        })

        this.elements.toggler = !this.elements.toggler ? document.querySelector('[data-sidebar-toggler]') : this.elements.toggler

        if(this.elements.toggler){
            this.elements.toggler.onclick = (event) => this.onClick()
        }

        if(this.elements.closer){
            this.elements.closer.onclick = (event) => this.onClick()
        }

        app.sidebar = this.element

        if((app.device.mobile && app.current == 'checkout') || (app.device.mobile && app.current == 'cart')){
            this.onClick(this.elements.toggler)
        }
    }

    onClick = (entry) => {
        app.root.classList.toggle('scroll--off')
        app.root.classList.toggle('sidebar--open')
        this.element.classList.toggle('sidebar--open')
    }

    addEventListeners(){
        window.addEventListener('resize', (event) => {
            if(!app.device.mobile){
                app.root.classList.remove('scroll--off')
                app.root.classList.remove('sidebar--open')
                this.element.classList.remove('sidebar--open')
            } 
        })
    }

    destroy = () => { /** Code your own */ }
}