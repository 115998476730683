import Animation from "../classes/Animation"

import each from 'lodash/each'
import gsap from 'gsap'
import SplitText from "gsap/SplitText"

import { random } from "../utils/math"

gsap.registerPlugin(SplitText);

import { app } from '../utils/store'

export default class ImagesInsideText extends Animation {
    constructor({ element }){
        super({
            element
        })

        this.element.innerHTML = this.element.innerHTML.replaceAll('\n', '<br>')

        let lineBreakers = this.element.querySelectorAll('br')
        lineBreakers[lineBreakers.length - 1].remove()
        
        new SplitText(this.element, {tag: 'span', wordDelimiter: '<br>', type: 'line'});
        new SplitText(this.element, {tag: 'span', type: 'words'});

        this.element.innerHTML = this.element.innerHTML.replace(/<br\s*\/?>/gi,'')
        this.animated = false

        // Lines
        this.lines = this.elements.text.children

        console.log(this.lines)
        
        if(this.lines){
            this.words = new Array()
            each(this.lines, (line) => {
                console.log(line)
                line.style = ""
                line.classList.add('line')
                this.words.push(...line.children)
            })
        }
        
        // Images
        this.images = this.element.getAttribute('data-title-images')

        if(this.images){
            each(JSON.parse(this.images), (entry, index) => {
                if(index < this.lines.length){
                    let src = entry
                    let line = this.lines[index == 0 ? 0 : (index + 1)]

                    let words = [...line.children]
                    
                    let image = new Image()
                    image.src = src
                    
                    line.insertBefore(image, words[parseInt(random(1, (words.length - 1)))])
                }
            })
            
            this.images = this.element.querySelectorAll('img')
        }

        for(let i = 0; i < parseInt(this.words.length / 4); i++){
            this.words[parseInt(random(0, this.words.length))].style.fontStyle = 'italic'
        }
    }

    animateIn(){
        this.timelineIn = gsap.timeline({
            delay: .5, 
        })

        if(!this.animated){
            this.timelineIn.set(this.element, {
                autoAlpha: 1,
            })
            
            each(this.words, (word, index) =>{
                word.parentNode.style.overflow = 'visible'
                
                this.timelineIn.fromTo(word, {
                    autoAlpha: 0,
                    y: '100%',
                },{
                    delay: index * .025,
                    duration: 1.5,
                    ease: 'expo.out',
                    autoAlpha: 1,
                    y: 0,
                    // clearProps: 'all',
                    onComplete: () => { 
                        // word.parentNode.style.overflow = 'visible'
                        // line.style.overflow = 'visible'
                    }
                }, 0)
            })
            
            each(this.images, (image, index) =>{
                this.timelineIn.fromTo(image, {
                    width: 0,
                },{
                    rotate: random(-5, 5),
                    width: 'auto',
                    duration: 1.5,
                    ease: 'expo.out',
                    // clearProps: 'all',
                    onComplete: () => { 
                    }
                }, '-=1')
            })
        }

        this.animated = this.element.hasAttribute('data-animation-once') ? true : false
    }

    animateOut(){
        // if(!this.animated){
        //     gsap.set(this.element, {
        //         autoAlpha: 0
        //     })
        // }
    }

    onResize() {
        // this.elementLines = calculate(this.elementLines)
    }
}