import Page from '../../classes/Page'

import { app } from '../../utils/store'

export default class SeekHelp extends Page{
    constructor(){
        super({
            id : 'seekhelp',
            element: '[data-app-page]',
            elements: {
                header: 'header',
                card: '.card--seek-help',
            }
        })
    }

    create() {
        super.create()

        // this.manipulateHTML()
    }

    // Manipulate HTML
    manipulateHTML = () => {
        if (app.device.mobile) {
            if(this.elements.card){
                this.card = document.createElement('div')
                this.card.classList = this.elements.card.classList
                this.card.innerHTML = this.elements.card.innerHTML
    
                this.elements.card.remove()
                this.elements.header.querySelector('.content').appendChild(this.card)
            }
        }
    }
    
    addEventListeners() {
        
    }

    // onResize() {
    //     super.onResize()
    // }

    destroy(){ 
        super.destroy()
    }
}