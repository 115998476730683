import Page from "../../classes/Page"
import { app } from '../../utils/store'

import each from 'lodash/each'

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default class LoginView extends Page {
    constructor() {
        super({
            id : 'login',
            element: '[data-app-page]',
            elements: {
                page: document.querySelector('[data-app-page]'),
                forms: '[data-forms]',
                login: '[data-form-login]',
                register: '[data-form-register]'
            }
        })
    }

    create() {
        super.create()

        this.register = {
            form: this.elements.register,
            toggler: this.elements.register.querySelector('button[data-register-toggler]'),
            registry: this.elements.register.querySelector('.registry'),
            cta: this.elements.register.querySelector('.register--cta')
        }
        this.register.toggler.onclick = (event) => this.onToggle(event, 'register')
        app.root.style.setProperty('--register-registry-height', `${this.register.registry.clientHeight}px`)
        app.root.style.setProperty('--register-cta-height', `${this.register.cta.clientHeight}px`)

        this.login = {
            form: this.elements.login,
            toggler: this.elements.register.querySelector('button[data-login-toggler]'),
            from_notice: document.querySelector('.woocommerce-notices-wrapper .showlogin')
        }
        this.login.toggler.onclick = (event) => this.onToggle(event, 'login')

        if (this.login.from_notice) {
            this.login.from_notice.onclick = (event) => this.onToggle(event, 'login')
        }
    }

    onToggle(event, type) {
        if(event){
            event.preventDefault()
        }

        if (type == 'register') {
            if (!app.device.mobile) {
                app.root.classList.add('registration--open')
                gsap.to(app.root, {
                    duration: .5,
                    onComplete: () => {
                        this.register.form.querySelector('input[name="username"]') ?
                            this.register.form.querySelector('input[name="username"]').focus() :
                            this.register.form.querySelector('input[name="email"]').focus()
                    }
                })
            } else {
                gsap.to(app.root, {
                    duration: .5,
                    onStart: () => {
                        gsap.to(window, { scrollTo: 0 })
                        app.root.classList.add('registration--open')
                    },
                    onComplete: () => {
                        this.register.form.querySelector('input[name="username"]') ?
                            this.register.form.querySelector('input[name="username"]').focus() :
                            this.register.form.querySelector('input[name="email"]').focus()
                    }
                })
            }
        } else if (type == 'login') {
            if (!app.device.mobile) {
                app.root.classList.remove('registration--open')

                gsap.to(app.root, {
                    duration: .5,
                    onStart: () => gsap.to(window, { scrollTo: 0 }),
                    onComplete: () => this.login.form.querySelector('input[name="username"]').focus()
                })
            } else {
                gsap.to(app.root, {
                    duration: .5,
                    onStart: () => {
                        gsap.to(window, { scrollTo: 0 })
                    },
                    onComplete: () => {
                        app.root.classList.remove('registration--open')
                        this.login.form.querySelector('input[name="username"]').focus()
                    }
                })
            }
        }
    }

    onFocus(entries, form = false){
        each(entries, (input) => {
            input.addEventListener('focus', (event) => {
                if (event.target === document.activeElement) {
                    if(form == 'login'){
                        app.root.classList.remove('registration--open')
                    } else if(form == 'register') {
                        app.root.classList.add('registration--open')
                    }
                }
            })
        })
    }

    addEventListeners() {
        window.addEventListener('resize', () => {
            app.root.style.setProperty('--register-registry-height', `${this.register.registry.clientHeight}px`)
            app.root.style.setProperty('--register-cta-height', `${this.register.cta.clientHeight}px`)
        })

        this.onFocus(this.login.form.querySelectorAll('input'), 'login')
        this.onFocus(this.register.form.querySelectorAll('input'), 'register')
    }

    validation(entries) { }

    manipulate(entry) { }
}