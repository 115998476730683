class Button{
    create({ element }){
        let span = document.createElement('span')
        span.innerHTML = element.innerHTML
        element.innerHTML = ''

        element.append(span)
    }
}

export const ButtonManager = new Button()