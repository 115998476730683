import Collapse from 'bootstrap/js/src/collapse'

import each from 'lodash/each'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

class Accordions{
    create(entry){
        new Collapse(entry)

        entry.addEventListener('click', (event) => {
            each(ScrollTrigger.getAll(), (timeline) => ScrollTrigger.refresh(timeline))
        })
            // entry.addEventListener('shown.bs.collapse', (event) => {
            //     console.log(event, ScrollTrigger.getAll())
            //     ScrollTrigger.refresh()
            // })
            // entry.addEventListener('hidden.bs.collapse', (event) => {
            //     console.log(event, ScrollTrigger.getAll())
            //     ScrollTrigger.refresh()
            // })
    }
}

export const AccordionManager = new Accordions()