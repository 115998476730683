import Component from "../classes/Component"
import { app, session, colors } from '../utils/store'

import each from 'lodash/each'

import imagesLoaded from 'imagesloaded'

import gsap from 'gsap'
import SplitText from "gsap/SplitText"
import { DEFAULT, BOUNCING } from '../utils/easings';

export default class Preloader extends Component {
    constructor() {
        super({
            element: '[data-preloader]',
            elements: {
                page: document.querySelector('[data-app-page]'),
                images: document.querySelectorAll('img'),
                container: '[preloader]',
                character: '#loop-loader',
                percentage: '.number--percentage',
                text: '[data-text]',
                skip: 'button[data-skip]',
                progress: 'div.progress',
                overlay: '[data-overlay]'
            }
        })

        app.html.classList.add('loading')

        this.elements.skip.onclick = () => {
            gsap.to(this.elements.skip, {
                autoAlpha: 0,
                y: 50,
                onStart: () => this.onLoaded(true)
            })
        }
    }

    create() {
        super.create()


        app.loader = this.element
        this.length = 0

        this.imgLoader = new imagesLoaded(this.elements.images)
        this.images = new Array()

        this.imgLoader.on('progress', (instance, entry, index) => {
            // console.log(instance, entry, index)
            var status = entry.isLoaded ? 202 : 404
            if (status === 202) {
                this.images.push(entry)
                this.onAssetLoaded(entry.img)
                // entry.img.src = app.webp ? entry.img.getAttribute('data-webp') : entry.img.getAttribute('data-src')
            } else if (status === 404) {
                console.warn(status, entry.img.src)
                delete this.elements.images[index]
            }
        })

        this.createLoader()

        session.init = sessionStorage.getItem('init')
        if (!session.init) {
            sessionStorage.setItem('init', true)

            this.percent = 1

            // TransitonEnd::Set initial state
            if (app.footer.element) { gsap.set(app.footer.element, { yPercent: 100 }) }
            if (app.viewport) { gsap.set(app.viewport, { scale: .8 }) }

            // Overlay::Set initial state
            if (this.elements.overlay) { gsap.set(this.elements.overlay, { xPercent: -100 }) }

            // Texts::Set initial state
            if (this.elements.text.children[0]) { gsap.set(this.elements.text.children, { autoAlpha: 0 }) }

            this.animateIn()
        } else {
            this.imgLoader.on('always', (instance) => {
                console.log('Done', instance)
                this.percent = 1
                this.onLoaded(true)
            })
        }
    }

    animateIn() {
        each(this.elements.text.children, (message, index) => {
            message.innerHTML.replaceAll('\n', '<br>')

            let lineBreakers = message.querySelectorAll('br')
            if (lineBreakers[0]) { lineBreakers[lineBreakers.length - 1].remove() }

            new SplitText(message, { tag: 'span', wordDelimiter: '<br>', type: 'line' })
            new SplitText(message, { tag: 'span', type: 'words' })
            message.innerHTML = this.elements.text.children[index].innerHTML.replace(/<br\s*\/?>/gi, '')

            // Words::Set visibility onInit
            gsap.set(message.querySelectorAll('span > span'), { autoAlpha: 0, yPercent: window.innerHeight })
            gsap.set(message, { autoAlpha: 1, clearProps: true })
        })

        this.animated = false

        let timelineIn = gsap.timeline({ defaults: { ease: DEFAULT } })

        timelineIn
            // Prase – 01
            .to(this.elements.text.children[0].querySelectorAll('span > span'), {
                duration: 1.5,
                autoAlpha: 1,
                yPercent: 0,
                stagger: {
                    amount: .15,
                }
            })

            // Prase – 02
            .to(this.elements.text.children[1].querySelectorAll('span > span'), {
                duration: 2,
                autoAlpha: 1,
                delay: 3.5,
                yPercent: 0,
                stagger: {
                    amount: .15,
                }
            })
            .to(this.elements.text.children[0].querySelectorAll('span > span'), {
                duration: 1,
                delay: -.5,
                autoAlpha: 0,
                yPercent: -100,
                stagger: {
                    amount: .15,
                    from: 'end'
                }
            }, '<')

            // Prase – 03
            .to(this.elements.text.children[2].querySelectorAll('span > span'), {
                duration: 2,
                autoAlpha: 1,
                delay: 2,
                yPercent: 0,
                stagger: {
                    amount: .15,
                }
            })
            .to(this.elements.text.children[1].querySelectorAll('span > span'), {
                duration: 1,
                delay: -.5,
                autoAlpha: 0,
                yPercent: -100,
                stagger: {
                    amount: .15,
                    from: 'end'
                }
            }, '<')

        //Ending
        timelineIn.call(() => {
            this.onLoaded()
        })

    }

    createLoader() {
        if (this.element.images !== 'undefined' || this.elements.images !== null || this.elements.images.length !== 0) {
            each(this.elements.images, (element) => {
                // element.onload = () => this.onAssetLoaded(element)
                element.src = app.webp ? element.getAttribute('data-webp') : element.getAttribute('data-src')
            })
        } else {
            gsap.to(this.element, {
                delay: .05,
                duration: 1.5,
                overwrite: true,
                backgroundColor: 'white',
                onUpdate: () => {
                    this.elements.percentage.innerHTML = `${Math.round(this.percent * 100)}%`
                },
                // onComplete: () => { this.onLoaded() }
            })
        }
    }

    onAssetLoaded = (image) => {
        this.length += 1

        image.setAttribute('loaded', '')

        this.percent = this.length / this.elements.images.length

        if (this.element) {
            gsap.to(this.element, {
                delay: .05,
                duration: 2.5,
                overwrite: true,
                // backgroundColor: 'white',
                onUpdate: () => {
                    gsap.to(this.elements.progress, {
                        '--progress': `${Math.round(this.percent * 100)}%`,
                    })
                    // this.elements.progress.style.setProperty('--progress', `${Math.round(percent * 100)}%`)
                }
            });

            this.elements.percentage.innerHTML = `${Math.round(this.percent * 100)}%`
        }

        if (this.percent === 1 || this.percent > 1) {
            this.elements.percentage.innerHTML = `100%`
        }
    }

    onLoaded = (skip = false) => {
        return new Promise(resolve => {
            
            if (!session.init) {
                this.animateOut = gsap.timeline({ delay: skip ? 0 : 2 })
                this.animateOut
                    .to(this.elements.text.children[1].querySelectorAll('span > span'), {
                        duration: 1.5,
                        autoAlpha: 0,
                        yPercent: -100,
                        stagger: {
                            amount: .15,
                            from: 'end'
                        }
                    }, 0)
                    // .to(this.element.children, {
                    //     duration: 1,
                    //     autoAlpha: 0,
                    // }, .05)
                    .to(this.elements.skip, {
                        duration: 1,
                        y: window.innerHeight / 2,
                    }, 0)
                    .to(this.elements.character, {
                        autoAlpha: 0,
                        duration: 1,
                    }, 0)
                    .to(this.elements.percentage, {
                        autoAlpha: 0,
                        duration: 1,
                        y: -50
                    }, 0)
                    .to(this.elements.text, {
                        autoAlpha: 0,
                    }, 0)
                    .to(app.viewport, {
                        duration: .9,
                        scale: 1,
                        clearProps: true,
                        ease: BOUNCING,
                    }, '<')
                    // .to(this.elements.overlay, {
                    //     duration: .5,
                    //     xPercent: 100,
                    //     ease: 'linear',
                    // },1)
                    // .to(this.elements.container, {
                    //     duration: .25,
                    //     // yPercent: -100,
                    //     clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
                    //     ease: 'linear',
                    // },1)
                    .to(this.elements.container, {
                        duration: 1,
                        backgroundImage: `radial-gradient(circle at center, transparent 100%, ${colors.secondary} 100%)`,
                        ease: BOUNCING,
                    }, '<')
                    .to(app.footer.element, {
                        duration: .75,
                        yPercent: 0,
                        ease: DEFAULT,
                    }, '>')
            } else {
                this.animateOut = gsap.timeline({ delay: 1 })
            }

            this.animateOut.call(() => {
                this.emit('completed')
                resolve()
            })
        })
    }

    destroy = () => {
        if (this.element) {
            gsap.to(this.element, {
                duration: .25,
                delay: 0,
                autoAlpha: 0,
                onComplete: () => { app.root.removeChild(this.element) }
            })
        }
    }
}
