import Component from './Component'
import { app } from '../utils/store'

import gsap from 'gsap'
import { DEFAULT } from '../utils/easings';

import each from 'lodash/each';
import { random } from '../utils/math';

export default class Video extends Component {
	constructor({ element }) {
		super({ element })

		this.createObserver()
	}

	createObserver(element) {
		this.observer = new window.IntersectionObserver((entries) => {
			each(entries,(entry) => {
				if (entry.isIntersecting && !this.element.hasAttribute('loaded')) { // If is in viewport
					if(entry.target.parentNode.tagName == 'FIGURE' && (!entry.target.parentNode.classList.contains('tile') && !entry.target.parentNode.classList.contains('image') && !entry.target.parentNode.classList.contains('double'))){ 
						this.element.play()
						if(!this.animated){	
							this.animateIn(entry.target)
						}
					} 
				} else {
					this.element.pause()
				}
			})
		}, {
			root: null,
			rootMargin: '10%',
		})

		this.observer.observe(this.element)
	}

    animateIn(entry) {
		let timeline = gsap.timeline({defaults: { duration: 1.5, delay: .5, ease: DEFAULT }})

		let overlay = document.createElement('div')
		overlay.classList.add('overlay--async')
		entry.parentNode.prepend(overlay)

		timeline
			.fromTo(entry.parentNode, {
				rotate: random(-5, 5),
				clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
			}, {
				rotate: 0,
				clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
				clearProps: true
			}, 0)
			.fromTo(overlay, {
				// autoAlpha: 1,
				clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
			}, {
				// autoAlpha: 0,
				clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
			}, .25)
			.fromTo(entry, {
				scale: 1.2,
				rotate: 5,
				webkitFilter: "brightness(10)",
    			filter: "brightness(10)",
			},{
				duration: 1.5,
				scale: 1,
				rotate: 0,
				webkitFilter: "brightness(1)",
    			filter: "brightness(1)",
				clearProps: true
			}, 0)

		timeline.call(() => {
			// entry.parentNode.style = ''
			overlay.remove()
			this.animated = true
		}) 
    }

    animateOut() {
        // That will be ovverride from animation/*.js
    }
}
