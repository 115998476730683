import Page from '../../classes/Page'

import Carousel from '../../modules/Carousel'
import { app } from '../../utils/store'

import gsap from 'gsap'
import each from 'lodash/each'
export default class About extends Page {
    constructor() {
        super({
            id: 'about',
            element: '[data-app-page]',
            elements: {
                charts: '[data-carousel="chart"]',
                founders: '.founders--container .message--container'
            }
        })
    }

    create() {
        super.create()

        // Create Modules
        this.createCarousel()
        // this.createReadMore()
        this.createParallax()
    }

    // Create Galleries
    createCarousel = () => {
        if (this.elements.charts) {
            return new Carousel({ element: this.elements.charts })
        }
    }

    // Create ReadMore
    createReadMore = () => {
        if (this.elements.founders) {
            each(this.elements.founders, (message) => {
                let paragraph = message.querySelector('.message p'),
                    more = message.querySelector('a.read-more')

                let collapse = 275,
                    full = paragraph.clientHeight

                paragraph.style.setProperty('--message-height', `${full}px`)
                paragraph.style.setProperty('height', `${collapse}px`)

                let open = false,
                    label = more.innerText

                more.onclick = (event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    if (!open) {
                        paragraph.style.setProperty('height', `${full}px`)
                        more.innerText = more.getAttribute('data-label')
                        open = true
                    } else {
                        paragraph.style.setProperty('height', `${collapse}px`)
                        more.innerText = label
                        open = false
                    }
                }
            })
        }
    }

    createParallax = () => {
        each(this.elements.founders, (message) => {
            message.addEventListener("mousemove", (event) => {
                const { left, top, width, height } = message.getBoundingClientRect()
    
                const halfWidth = width / 2
                const halfHeight = height / 2
                const mouseX = event.x - left
                const mouseY = event.y - top
    
                const x = gsap.utils.interpolate(-halfWidth, halfWidth, mouseX / width)
                const y = gsap.utils.interpolate(-halfHeight, halfHeight, mouseY / height)
    
                const figureX = gsap.utils.interpolate(-width, width, mouseX / width)
                const figureY = gsap.utils.interpolate(-height, height, mouseY / height)
    
                // each(this.elements.founders, (message) => {
                    gsap.to(message.querySelector('figure .photo'), {
                        x: (figureX * 0.025),
                        y: (figureY * 0.025),
                        rotateX: (figureY * 0.075),
                        rotateY: -(figureX * 0.05),
                        duration: 1,
                        ease: 'Power1.easeOut',
                        overwrite: true
                    })
                // })
            })
    
            message.addEventListener("mouseleave", (event) => {
                each(this.elements.founders, (message) => {
                    gsap.to(message.querySelector('figure .photo'), {
                        x: 0,
                        y: 0,
                        rotateX: 0,
                        rotateY: 0,
                        ease: 'Power3.easeOut',
                        duration: 20,
                        overwrite: true
                    })
                })
            })
        })
    }

    addEventListeners() {

    }

    // onResize() {
    //     super.onResize()
    // }

    destroy() {
        super.destroy()
    }
}