import Component from "../classes/Component"
import { app } from '../utils/store'

import { validate, invalidate } from '../utils/states'
import each from 'lodash/each'

export default class Form extends Component {
    constructor({ element }){
        super({ element })
        
        this.inputs = this.element.querySelectorAll('input')
    }

    addEventListeners() {
        this.validation(this.inputs)
    }

    validation(entries){
        each(entries, (input) => {
            input.addEventListener('change', (event) => {
                // Input::Number,Text,Phone,Password,Textarea
                if(
                    event.target.getAttribute('type') == 'number' ||
                    event.target.getAttribute('type') == 'text' ||
                    event.target.getAttribute('type') == 'tel' ||
                    event.target.getAttribute('type') == 'password' ||
                    event.target.getAttribute('type') == 'textarea'
                ){
                    if (event.target.value != '') { validate(event.target) }
                    else { invalidate(event.target) }
                }
                // Input::Email
                else if (event.target.getAttribute('type') == 'email'){
                    if(!event.target.value.includes('@')){ invalidate(event.target) } 
                    else { validate(event.target) }
                }
                // Input::Url
                else if (event.target.getAttribute('type') == 'url'){
                    if(!event.target.value.includes('http')){ invalidate(event.target) } 
                    else { validate(event.target) }
                }
                // Input::File
                else if (event.target.getAttribute('type') == 'file'){
                    if (!event.target.value) { invalidate(event.target) }
                    else { validate(event.target) }
                }
            })

            if(input.type == 'radio'){
                input.addEventListener('click', (event) => { })
            }
        })
    }

    billingBehaviour(entry = this.element){
        let form = entry;
        let inputs = {
            type: form.querySelectorAll('input[name="billing_type"]'), 
            checked: form.querySelector('input[name="billing_type"]:checked'),
            professional: form.querySelectorAll('.input-container.billing-professional'),
            private: form.querySelectorAll('.input-container.billing-private'),
        }

        // On Load
        if(inputs.checked == form.querySelector('input#billing_type_billing_professional')){
            form.querySelector('input#billing_type_billing_private').removeAttribute('checked')
            each(inputs.professional, (field) => { field.classList.remove('boolean--hidden') })
            each(inputs.private, (field) => { field.classList.add('boolean--hidden') })
        } else if(inputs.checked == form.querySelector('input#billing_type_billing_private')){
            form.querySelector('input#billing_type_billing_professional').removeAttribute('checked')
            each(inputs.private, (field) => { field.classList.remove('boolean--hidden') })
            each(inputs.professional, (field) => { field.classList.add('boolean--hidden') })
        }

        // On Click
        each(inputs.type, (input) => {
            if(input.type == 'radio'){
                input.addEventListener('click', (event) => {
                    let isBillingType = event.target.getAttribute('name') == 'billing-type',
                    isProfessional = event.target.id == 'billing_type_billing_professional',
                    isPrivate = event.target.id == 'billing_type_billing_private'

                    console.log(event.target)
                    
                    let checked = form.querySelector('input#'+event.target.id+':checked')
                    
                    if(isProfessional){
                        form.querySelector('input#billing_type_billing_private').removeAttribute('checked')
                        each(inputs.professional, (field) => { field.classList.remove('boolean--hidden') })
                        each(inputs.private, (field) => { field.classList.add('boolean--hidden') })
                    }

                    if(isPrivate){
                        form.querySelector('input#billing_type_billing_professional').removeAttribute('checked')
                        each(inputs.private, (field) => { field.classList.remove('boolean--hidden') })
                        each(inputs.professional, (field) => { field.classList.add('boolean--hidden') })
                    }
                })
            }
        })
    }
}