export function activate(entry){
    entry.classList.add('active');
}
export function deactivate(entry){
    entry.classList.remove('active');
}
export function toggleActive(entry){
    entry.classList.toggle('active');
}
export function disable(entry){
    entry.setAttribute('disabled','');
}
export function available(entry){
    entry.removeAttribute('disabled');
}
export function toggleDisable(entry){
    entry.removeAttribute('disabled');
}
export function validate(entry){
    entry.classList.add('valid');
    entry.classList.remove('invalid');
}
export function invalidate(entry){
    entry.classList.remove('valid');
    entry.classList.add('invalid');
}