import Page from '../../classes/Page'
import each from 'lodash/each'

import { app } from '../../utils/store'
import { clamp, range } from '../../utils/math'
import { injectScript } from '../../utils/dom'

import gsap from 'gsap'
import { Flip } from 'gsap/Flip'
import { CSS } from '../../utils/easings'

import Player from '@vimeo/player'

gsap.registerPlugin(Flip)

export default class Home extends Page {
    constructor() {
        super({
            id: 'home',
            element: '[data-app-page]',
            elements: {
                areas: '[data-area]',
                slider: '[data-handler]',
                video: '[data-area="video"]',
                sprite: 'figure[spritesheet]',
            }
        })
    }

    create() {
        super.create()

        // Create Modules
        this.createAreas()
        this.createSlider()
        // this.YouTube()
        this.Player()
    }

    // Create Areas
    createAreas = () => {
        if (this.elements.areas) {
            each(this.elements.areas, (area) => {
                area.style.setProperty('--area-height', `${area.clientHeight}px`)
            })
        }

        if (!app.device.mobile && this.elements.sprite) {
            console.log(app.device)
            let sprite = {
                element: this.elements.sprite,
                src: this.elements.sprite.getAttribute('spritesheet'),
                image: this.elements.sprite.querySelector('img.spritesheet')
            }

            window.addEventListener('load', () => {
                let image = new Image()
                image.src = sprite.src
                image.id = `spritesheet-${sprite.element.id}`
                image.classes = `spritesheet`
                image.alt = 'Coffee Pills'
                
                // sprite.image.id = `spritesheet-${sprite.element.id}`
                // sprite.image.onload = () => {
                image.onload = () => {
                    let timeline = gsap.timeline()

                    timeline.to(sprite.element, {
                        xPercent: 100,
                        onComplete: () => {
                            sprite.element.append(image)
                            sprite.image = document.getElementById(image.id)
                            sprite.frames = parseInt(image.clientWidth / image.clientHeight)
                            sprite.animation = gsap.timeline({
                                defaults: { ease: `steps(${sprite.frames - 1})` },
                                paused: true,
                            })
        
                            sprite.animation.to(sprite.image, {
                                x: (-sprite.image.clientHeight * (sprite.frames - 1)),
                            })
        
                            this.elements.sprite.parentNode.onmouseenter = () => { sprite.animation.play() }
                            this.elements.sprite.parentNode.onmouseleave = () => { sprite.animation.reverse() }
        
                        }
                    })
                    .to(sprite.element, {
                        delay: .5,
                        xPercent: 0,
                        onStart: () => {
                            sprite.element.style.removeProperty('background-image')
                            // sprite.element.removeAttribute('style')
                        }
                    })

                }
            })
        }
    }

    //
    createSlider = () => {
        let dragging = false
        let knobOffset = 0

        let track = this.elements.slider,
            knob = this.elements.slider.children[0],
            container = this.elements.slider.parentNode,

            trackWidth = track.clientWidth,
            trackLeft = track.offsetLeft,
            trackRight = trackLeft + trackWidth,

            knobWidth = knob.offsetWidth,
            maxRight = trackWidth - knobWidth // relatively to track

        knob.onmousedown = (event) => {
            // knob offset relatively to track
            dragging = true
            container.classList.add('dragging')
            knobOffset = event.clientX - knob.offsetLeft
        }

        container.onmouseup = (event) => {
            dragging = false
            container.classList.remove('dragging')
        }

        container.onmouseleave = (event) => {
            dragging = false
            container.classList.remove('dragging')
        }

        container.onmousemove = (event) => {
            if (dragging) {
                let width = range(event.clientX - trackLeft, 0, trackWidth, 0, 100)
                // current knob offset, relative to track
                var offset = event.clientX - trackLeft - knobOffset
                if (offset < 0) {
                    var offset = 0
                } else if (offset > maxRight) {
                    var offset = maxRight
                }

                // knob.style.left = offset + "px"
                knob.style.width = `${clamp(0, 100, width)}%`

                console.log(clamp(0, trackWidth, event.clientX - trackLeft))
            }
        }


        // this.timeline = gsap.timeline({ onUpdate: updateSlider })

        // this.timeline.set("#content", { visibility: "visible" })
        //     .from("h1", 0.5, { left: 100, autoAlpha: 0 }) // autoAlpha handles both css properties visibility and opacity.
        //     .from("h2", 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") //add tween 0.25 seconds before previous tween ends
        //     .from("#feature", 0.5, { scale: 0.5, autoAlpha: 0 }, "feature") // add feature label at start position of this tween
        //     .from("#description", 0.5, { left: 100, autoAlpha: 0 }, "feature+=0.25") // add tween 0.25 seconds after the feature label
        //     .staggerFrom("#nav img", 0.5, { scale: 0, rotation: -180, autoAlpha: 0 }, 0.2, "stagger")



        // $("#slider").slider({
        //     range: false,
        //     min: 0,
        //     max: 100,
        //     step: .1,
        //     slide: function (event, ui) {
        //         this.timeline.pause()
        //         //adjust the timeline's progress() based on slider value
        //         this.timeline.progress(ui.value / 100)
        //     }
        // })

        // function updateSlider() {
        //     $("#slider").slider("value", tl.progress() * 100)
        // }
    }

    createVideo = () => {
        let teaser = this.elements.video.querySelector('video'),
            closer = this.elements.video.querySelector('[data-closer]'),
            video = this.elements.video.querySelector('iframe')

        // Teaser
        // teaser.play()
        if (!!(teaser.currentTime > 0 && !teaser.paused && !teaser.ended && teaser.readyState > 2)) {
            teaser.play()
        }

        // console.log(this.getStatus({ 
        //     player: this.player,
        //     callback: `getPaused`
        // }))

        // console.log(this.player)
        // onClick::Area
        this.elements.video.onclick = (event) => {
            let state = Flip.getState(this.elements.video)

            // DOM::Node Manipulation
            this.elements.video.classList.toggle('transitioning')

            // State::Transition (Page Transition Fired)
            Flip.from(state, {
                duration: .5,
                ease: CSS,
                onStart: () => {
                    // app.loading = true
                    // this.player.playVideo()
                    // if(this.player.paused)
                    this.player.play()
                },
                onComplete: () => {
                    // app.root.style.cursor = 'progress'
                    // app.root.style.pointerEvents = 'none'
                    // console.log(YT)
                }
            })
        }

        // onClick::Closer
        closer.onclick = (event) => {
            event.preventDefault()
            event.stopPropagation()
            this.elements.video.click()
            this.player.pause()
        }
    }

    Player = () => {
        this.player = new Player('player', {
            id: 948378212,
            width: `100%`,
            loop: false,
            airplay: true,
            byline: false,
            cc: false,
            color: 'FCC0D8',
            play_button_position: 'center',
            muted: false,
            playsinline: true,
            progress_bar: false,
            quality: '1080p',
            responsive: true,
            title: false,
            vimeo_logo: false,
        })

        // this.player.setVolume(0)

        this.player.on('play', () => {
            // console.log('played the video!')
        })

        this.player.on('ended', () => {
            // console.log('ended the video!')
            this.elements.video.click()
        })

        this.player.getVideoTitle().then(function (title) {
            console.log('title:', title)
        })

        this.createVideo()
    }

    getStatus = async ({ player = null, callback = null }) => {
        if (player && callback) {

            let request = await player.callback()
            if (request.status === 200) {
                console.log(request)
            }
        } else {
            console.warning("Not 'player' or 'callback' defined")
        }
    }

    // YouTube = async () => {
    //     let script = document.getElementsByTagName('script')[0]

    //     await injectScript("https://www.youtube.com/iframe_api",{
    //         id: 'youtube', 
    //         target: script, 
    //         insertBefore: true
    //     })

    //     let done = false
    //     window.YT.ready(() => {
    //         this.player = new YT.Player('player', {
    //             height: (window.visualViewport.clientWidt * 9) / 16,
    //             width: window.visualViewport.clientWidth,
    //             videoId: 'JtSG9TOqObc',
    //             playerVars: {
    //                 origin: config.site,
    //                 autoplay: 0,
    //                 controls: 1,
    //                 modestbranding: 1,
    //                 rel: 0,
    //                 showinfo: 0,
    //                 loop: 1,
    //                 disablekb: 1,
    //                 fs: 0,
    //                 iv_load_policy: 3,
    //                 playsinline: 1,
    //                 color: 'white'
    //             },
    //             events: {
    //                 'onReady': (event) => { event.target.setPlaybackQuality('highres') },
    //                 'onStateChange': (event) => {
    //                     // console.log(event.data)
    //                     if(event.data == YT.PlayerState.ENDED){
    //                         this.elements.video.click()
    //                     }

    //                     if (event.data == YT.PlayerState.PLAYING && !done) {
    //                         setTimeout(this.player.stopVideo(), 6000)
    //                         done = true
    //                     }
    //                 }
    //             }
    //         })

    //         this.createVideo()
    //     })



    //     // 4. The API will call this function when the video player is ready.

    //     // 5. The API calls this function when the player's state changes.
    //     //    The function indicates that when playing a video (state=1),
    //     //    the player should play for six seconds and then stop.
    //     // function stopVideo() { player.stopVideo() }
    // }

    addEventListeners() {
        window.visualViewport.addEventListener('resize', () => {
            if (this.elements.areas) {
                each(this.elements.areas, (area) => {
                    area.style.setProperty('--area-height', `${area.clientHeight}px`)
                })
            }
        })
    }

    destroy() {
        super.destroy()
    }
}